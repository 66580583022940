<template>
  <main class="interviewsList">
    <div class="container">
      <div class="tw-max-w-4xl tw-mx-auto tw-flex md:tw-justify-end tw-mb-4 tw-mt-6">
        <span class="tw-inline-block tw-w-28 tw-text-xs tw-whitespace-nowrap tw-text-center tw-px-4 tw-py-1 tw-rounded-full tw-border tw-text-[#422E87] tw-border-[#422E87] tw-select-none">
          {{ $t('matchedTalents.beta') }}
        </span>
      </div>
      <div class="interviewsList__header">
        <h2 class="interviewsList__mainHeading">
          <span> {{ $t('interviewsList.requests') }}</span>
        </h2>

        <div class="interviewsList__navigation">
          <button
            class="interviewsList__navItem"
            :class="{ active: activeStatus == 'upcoming', disabled: isLoading }"
            :disabled="isLoading"
            @click="activeStatus = 'upcoming'"
          >
            {{ $t('interviewsList.upcoming') }}
          </button>
          <button
            class="interviewsList__navItem"
            :class="{ active: activeStatus == 'history', disabled: isLoading }"
            :disabled="isLoading"
            @click="activeStatus = 'history'"
          >
            {{ $t('interviewsList.history') }}
          </button>
        </div>
      </div>

      <div class="interviewsList__wrapper">
         
          <div class="interviewsList__cardsWrapper">
            <template v-if="!isLoading && interviewCards.length || paginationActive">
              <div
                v-for="(interview, index) in interviewCards"
                :key="index"
              >
                <interview-card
                  :interview-card="interview"
                />
                <div v-if="index === interviewCards.length - 1 && interviewCards.length != listCount" class="interviewsList__pagination">
                  <span>
                    {{ interviewCards.length }} / {{ listCount }}
                  </span>
                  <button @click="getNextPage()">
                    <span>{{ $t("interviewsList.more") }}</span>
                    <div v-if="isLoading" class="pagination-loader"></div>
                  </button>
                </div>
              </div>
            </template>
            <div v-else-if="!isLoading && !interviewCards.length" class="tw-text-center tw-mt-12 tw-mb-12">
              <img src="@/assets/images/contract-renewal/no-contract.svg" alt="No Contracts" class="mx-auto tw-w-64">
              <p class="tw-text-xl tw-font-bold tw-mt-8 tw-text-black">
                {{ $t('interviewsList.emptyList') }}
              </p>
            </div>
            <skeleton class="tw-mt-5" v-else-if="isLoading && !paginationActive" />
          </div>
      </div>
    </div>
  </main>
</template>

<script>
import { mapGetters } from "vuex";
import InterviewCard from '@/components/interviewCard/index.vue';
import skeleton from '@/components/interviewCard/skeleton.vue';


export default {
  name: "interviewsList",
  components: {
    InterviewCard,
    skeleton
  },
  metaInfo() {
    const projectName = this.activeProject?.name || 'Interviews List'
    return {
      title: `${projectName} | Squadio`,
    };
  },
  data() {
    return {
      selecting: false,
      isLoading: false,
      activeStatus: 'upcoming',
      paginationActive: false,
      page: 1,
      interviewCards: [],
      listCount: null
    };
  },
  computed: {
    ...mapGetters({
      activeProject: "client/getActiveProject",
      user: "auth/getUser",
    }),
  },
  watch: {
    activeProject() {
      this.interviewCards = [];
      this.getInterviewsList();
    },
    activeStatus() {
      this.interviewCards = [];
      this.getInterviewsList();
    }
  },
  created() {
    this.getInterviewsList();
  },
  methods: {
    getNextPage() {
      this.page++;
      this.paginationActive = true;
      this.getInterviewsList();
    },
    async getInterviewsList() {
      this.isLoading = true;
      try {
        await this.axios
          .get(`/api/client/interviews?page=${this.page}${this.activeStatus == 'history' ? '&history=true' : ''}`)
          .then((res) => {
            this.interviewCards = [ ...this.interviewCards, ...res.data.data];
            this.listCount = res.data.meta.total
            if (this.interviewCards.length) {
              const activeProject = JSON.parse(localStorage.getItem("activeProject"));
              activeProject.name = this.interviewCards[0].project_name;
              localStorage.setItem("activeProject", JSON.stringify(activeProject));
            }
          });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
}
</script>

<style lang="scss">
.interviewsList {
  &__wrapper {
    position: relative;
    z-index: 0;
    background-color: #fff;
    padding: 20px;
    border-radius: 18px;
    margin: 2.6rem auto 0;

    @media (min-width: 1200px) {
      width: 84%;
    }
    @media (min-width: 1400px) {
      width: 70%;
    }
  }

  &__mainHeading {
    font-size: 30px;
    display: flex;
    justify-content: flex-start;
    margin: auto 0;
    @media (max-width: 768px) {
      font-size: 24px;
    }
  }

  &__header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: auto;
    gap: 20px;
    max-width: 56rem;

    @media (min-width: 776px) {
      flex-direction: row;
    }
  }

  &__navigation {
    display: flex;
    align-items: center;
    gap: 20px;
    overflow-x: scroll;
    margin-top: 24px;
    padding: 8px;
    border-radius: 30px;
    background-color: #fff;
    box-shadow: 9px 7px 45px 0 rgba(66, 46, 135, 0.09);
    scrollbar-width: none;
    margin-bottom: 24px;


    @media (max-width: 768px) {
      padding: 8px 15px;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    * {
      scrollbar-width: none;
    }
  }

  &__navItem {
    border-radius: 30px;
    padding: 8px 20px;
    display: flex;
    align-items: center;
    color: #494d55;
    font-weight: 600;
    font-size: 16px;
    white-space: nowrap;
    justify-content: center;

    &.active {
      background-color: rgba(255, 198, 41, 1);
      color: #1e2022;
    }
    &.disabled {
      opacity: 0.75;
      cursor: not-allowed;
    }

    .loader {
      margin-inline-start: 7px;
    }
  }

  &__multipleSelect {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      width: 250px;
      padding: 16px;
      border: 1px solid rgba(241, 241, 241, 1);
      border-radius: 12px;
      transition: box-shadow .3s ease;

      svg {
        width: 21px;
        height: 21px;
      }

      span {
        font-size: 14px;
        font-weight: 600;
      }

      &:hover {
        box-shadow: 4px 2px 15px 0 rgba(66, 46, 135, 0.09);
      }
    }

    &.disabled {
      button {
        cursor: not-allowed;
        opacity: 0.5;
      }
    }
  }

  &__groupActions {
    position: fixed;
    bottom: 0;
    width: 100vw;
    background-color: #fff;
    z-index: 10;

    .container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 8px;
      padding-block: 20px;

      @media (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;
      }
    }

    p {
      font-size: 16px;
      color: #000;
    }

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      width: 160px;
      padding: 16px;
      border-radius: 12px;
      color: #fff;
      transition: box-shadow .3s ease;

      img {
        width: 21px;
        height: 21px;
      }

      span {
        font-size: 14px;
        font-weight: 600;
      }

      &:hover {
        box-shadow: 4px 2px 15px 0 rgba(66, 46, 135, 0.09);
      }
    }
  }

  &__groupActionsCTAs {
    display: flex;
    gap: 16px;

    @media (max-width: 768px) {
      width: 100%;
      justify-content: space-between;
    }

    button:first-child {
      background-color: #26daa6;
    }
    button:last-child {
      background-color: #ff6f59;
    }
  }

  &__pagination {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;

    span {
      font-size: 14px;
      color: #000;
    }

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      padding: 10px 40px;
      border-radius: 12px;
      color: #fff;
      transition: box-shadow .3s ease;
      box-shadow: 4px 2px 15px 0 rgba(66, 46, 135, 0.09);

      img {
        width: 21px;
        height: 21px;
      }

      span {
        font-size: 14px;
        font-weight: 600;
      }
    }
  }
}

.pagination-loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid rgba(255, 198, 41, 1);
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

html[dir="rtl"] {
  .interviewsList__mainHeading {
    /* flex-direction: row-reverse; */
    justify-content: flex-start;
  }
}
</style>